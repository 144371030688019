import React, {Component} from 'react'

export default class BookBet extends Component {
  state = {bookingCode: ''}
  onInputChange = e => {
    this.setState({bookingCode: e.target.value})
  }
  render() {
    return (
      <div className="book-bet">
        <p className="book-bet__info">ต้องการวางแทงบอล ให้คลิกที่อัตราต่อรอง หรือ ใส่รหัสการจอง</p>

        <div className="">
          <select className="uk-select">
            <option>แทงบอลกับ AVFREEX24</option>
            <option>แทงบอลกับ PNCKDEVAPP</option>
            <option>แทงบอลกับ 100RUENG</option>
          </select>
        </div>
        <div className="">
          <input className="uk-input" type="text" placeholder="Booking Code" value={this.state.bookingCode} onChange={this.onInputChange} />
        </div>
        <button className={`uk-button uk-button-default uk-width-1-1 ${this.state.bookingCode ? 'active' : 'inactive'}`}>เรียกข้อมูล</button>
        <p className="book-bet__detail">รหัสโผยบอล สามารถโอนเดิมพันระหว่างเจ้ามือได้.</p>
      </div>
    )
  }
}
