import React from 'react'
import {ReactComponent as Twitter} from '../assets/icons/twitter.svg'
export default function Footer() {
  return (
    <footer className="footer">
      <div className="credit">
        ไม่ต้องการติดต่อ {' '}
        <a target="_blank" rel="noopener noreferrer" href="https://pnckdevapp.com/">
          PNCKDEVAPP
        </a>
      </div>
      <div className="owner">
        <div className="socials">
          <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/pnckdevapp">
            <Twitter />
          </a>
        </div>
      </div>
    </footer>
  )
}
